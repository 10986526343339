<script setup lang="ts">
import { UI05Button, UI05Modal } from 'ui-05'
import { storeToRefs } from 'pinia'
import { useConfirmRemovePaymentCardStore } from '~/store/modal/confirmRemovePaymentCard'

const { active, onConfirm, onReject } = storeToRefs(useConfirmRemovePaymentCardStore())
</script>

<template>
  <UI05Modal
    :show="active"
    overlay-theme="light"
    title=""
    max-width="360px"
    @close="onReject"
  >
    <template #default>
      <div class="modal__image">
        <img src="./asset/illustration-card.jpg" alt="Карта">
      </div>
      <p class="modal__title">
        Точно удалить карту?
      </p>
      <p class="modal__desc">
        После удаления карты,<br> в Близко её данные<br> придётся вводить заново
      </p>
      <UI05Button
        class="mb-8"
        fluid
        theme="red"
        size="49"
        @click="onConfirm"
      >
        Удалить
      </UI05Button>
      <UI05Button
        fluid
        theme="white"
        size="49"
        class="mr-14"
        @click="onReject"
      >
        Оставить все как есть
      </UI05Button>
    </template>
  </UI05Modal>
</template>

<style lang="postcss" scoped>
.modal {

  &__image {
    min-height: 138px;
    margin-top: -12px;
    text-align: center;
  }

  &__title {
    margin-bottom: 12px;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    text-align: center;
  }

  &__desc {
    max-width: 370px;
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 22px;
    color: #181818;
    text-align: center;
  }

  @media (max-width: 800px) {

    &__title {
      margin-bottom: 10px;
      font-size: 19px;
      line-height: 21px;
      text-align: left;

    }

    &__desc {
      margin-bottom: 15px;
      font-size: 14px;
      line-height: 20px;
      color: #A2A2A2;
      text-align: left;
    }
  }
}
</style>
